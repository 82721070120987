import React,{useRef} from 'react';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';



const ResultDiv = (props) => {
  
  const ResultDivRef = useRef();
 //const date = new Date().
  const onDownloadBtn = () => {
    const result = ResultDivRef.current;
    domtoimage
      .toBlob(result)
      .then((blob) => {
        const saveConfirm = window.confirm("이미지를 저장하시겠습니까?")
        if(saveConfirm === true){
        saveAs(blob, 'Oil.png');
        }}
    );
  };

  // const [sidoName, setSidoName] = React.useState("");
  // const [sigunguName, setSigunguName] = React.useState("");

// const localName = { sidoName:"", sigunguName:"" };

//  props.state.sidoList.forEach(element => {
//        if(element.id==props.state.sido){
//            setSidoName(element.name);
//        }
//  });

//  console.log("localName : " + localName.sidoName);

  return (
     <div style={{display : 'flex', justifyContent:'center', position: 'absolute', top: 0, left: 200, right: -200, bottom: 0, margin: 10}} >
        <div ref={ResultDivRef} className="Result" style={{backgroundColor: "#ddd", width: 400, height: 400, border:'none', borderRadius:10, padding: 10}} >
          
          <div className ="capture" >
            <h1>유류비 계산 결과</h1>
            <div className ="resultform" style={{display:'none'}} >
                  <div>평균유가 : {parseFloat(props.state.avgPrice).toFixed(2)} 원</div>
                  <div>가중치적용(125%) {parseFloat(props.state.avgPrice * 1.25).toFixed(2)} 원</div>
                  
                  <div>적용지역 : {props.state.sidoName} {props.state.sigunguName}</div>
                  <div>적용기간 : {props.state.strSDate} ~ {props.state.strEDate}</div>
                
                  { props.state.trip===true ?  <div>계산일수 : {1} 일</div> : <div>계산일수 : {props.state.work} 일</div>}
                      
                  <div>적용연비(125%) {props.state.effi * 1.25} km/L</div>
                
                  { props.state.shutt===true? <div>적용거리 : {props.state.dist} km/h</div> : <div>적용거리 : {props.state.dist} km/h</div> }
                  
                  <div>  {parseFloat(props.state.avgPrice * 1.25).toFixed(2)} 원 X {props.state.dist} km ÷ {props.state.effi * 1.25} km/L X {props.state.work} 일 =  {parseFloat(props.state.avgPrice*1.25*props.state.dist/(props.state.effi * 1.25)*props.state.work).toFixed(2)} 원<br/> </div>
                  
                  <div>최종 금액 : {parseFloat(props.state.avgPrice*1.25*props.state.dist/(props.state.effi * 1.25)*props.state.work).toFixed(2)} 원</div>
                  <div className="saveInfo">
                    주식회사 레티널<br/>
                  </div>  
                  <div className="download">
                    <button className='downBtn' onClick={onDownloadBtn}> 다운로드 버튼  </button>
                  </div>
                 </div>
            </div>
           
        </div>
    </div>
  );
};

export default ResultDiv;