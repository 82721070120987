import React, { Component, } from 'react';
// React에서 fetch 대용으로 사용
import axios from 'axios';
import DatePicker from 'react-datepicker';
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import Capture from './Capture';

class App extends Component {

  constructor(props){
      super(props);
      this.state = { work:0, effi:0, dist:0, sidoList:[], sido:"",sigunguList:[], sigungu:"", 
      oilType:"",trip:false, shutt:false, startDate: new Date(), endDate:new Date(),avgPrice:0,
      strSDate:"",strEDate:"",sidoName:"",sigunguName:""
    };
  }
  
  //  재시작시 실행할 함수.
  componentDidMount(){
      this._getSido();
    // this._getUserInfo();
  }

  //---------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------
  _getSido = async() =>{
        const res = await axios.get('http://192.168.29.27:5000/getSido');
        this.setState({sidoList:res.data});
  }

  _getSigungu = async(qText) =>{
    console.log("qText : " + qText);

    const res = await axios.post('http://192.168.29.27:5000/getSigungu', { text:qText });
    
    this.setState({sigunguList:res.data});
  }

  _postQuery = async(qText)=>{
    const res = await axios.post('http://192.168.29.27:5000/getAvg', {text: qText})
    if(res.data[0].Average ===0 || res.data[0].Average ===null)
    {
      const qry="select avg(price) as 'Average' from `oildata` where (oilType ='"+this.state.oilType+"' and sidoId='"+this.state.sido+
      "') and( DATE(date) between '"+ new Date(this.state.startDate).getFullYear()+ "-"+ (new Date(this.state.startDate).getMonth()+1)+"-"+ new Date(this.state.startDate).getDate() +
      "' and '"+new Date(this.state.endDate).getFullYear()+ "-" +(new Date(this.state.endDate).getMonth()+1) + "-" +new Date(this.state.endDate).getDate()+"')";
      this._repostQuery(qry);
    }else{
      this.setState({avgPrice:res.data[0].Average});
      console.log(this.state.avgPrice);
    }
  }

  _repostQuery = async(qText)=>{
    const res = await axios.post('http://192.168.29.27:5000/getAvg',{text:qText})
    this.setState({avgPrice:res.data[0].Average});
    console.log(this.state.avgPrice);
  }

  _getSidoName = async(sidoID)=>{
    const res = await axios.post('http://192.168.29.27:5000/getSidoName',{text:sidoID})
    this.setState({sidoName:res.data[0].name});
  }

  _getSigunguName = async(sigunguID)=>{
    const res = await axios.post('http://192.168.29.27:5000/getSigunguName',{text:sigunguID})
    this.setState({sigunguName:res.data[0].name});
  }

  //---------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------
  handleInput = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };
  
  handleChange = ({ target: { name, value } }) => {
    if(name === "sido"){
      const queryText = "select id, code ,name from `sigungu` where sidoId ='"+value+"' order by id";
      this._getSigungu(queryText);
    }

    this.setState({ [name]: value });
  };

  changeCheckBox = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  }

  startDateChange = (date) => {
    this.setState({ startDate: date});
  };
  
  endDateChange = (date) => {
    this.setState({ endDate: date });
  };
  
  changeText = () => {
    let sDate = new Date(this.state.startDate);
    let eDate = new Date(this.state.endDate);
    
    let sDateStr = sDate.getFullYear()+ "-"+ (sDate.getMonth()+1)+"-"+ sDate.getDate();
    let eDateStr = eDate.getFullYear()+ "-" +(eDate.getMonth()+1) + "-" + eDate.getDate();

    this.setState({strSDate: sDateStr});
    this.setState({strEDate: eDateStr});

    const queryText = "select avg(price) as 'Average' from `oildata` where (oilType ='"+this.state.oilType+"' and sidoId='"+this.state.sido+"'and sigunguId ='"+this.state.sigungu+"') and( DATE(date) between '"+sDateStr+"'and '"+eDateStr+"')";
    const queryText2 = "select name from sido where id="+this.state.sido;
    const queryText3 = "select name from sigungu where id="+this.state.sigungu;

    this._postQuery(queryText);
    this._getSidoName(queryText2);
    this._getSigunguName(queryText3);

    //this.props.navigate('/ResulPage');
    
    // async axios({
    //   method:'post',
    //   url:'/result',
    //   data:{text: queryText}
    //   }).then((result)=>{console.log('success'); this.setState({avgPrice:result.data[0].Average});
    // }).catch((error)=>{console.log('fail');console.log(error)})
  
    console.log(this.state.sidoList);
    console.log(this.state.sido);
    console.log(this.state.sigungu);
    console.log(this.state.oilType);
    console.log(this.state.trip);
    console.log(this.state.shutt);
    console.log(this.state.work);
    console.log(this.state.effi);
    console.log(this.state.dist);
    //console.log(sDate.getFullYear() + "-"+(sDate.getMonth()+1) + "-" + sDate.getDate() );
    console.log(eDate.getFullYear() + "-"+(eDate.getMonth()+1) + "-" + eDate.getDate() );
    console.log(queryText);     
    const result = document.querySelector('.resultform');
    const filter=(d)=>{
      return d.style.display="inline"; 
    };
    
    filter(result);
  };

  //---------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------
  render() 
  { 
      const { work, effi, dist, sido, sigungu, oilType,trip,shutt, startDate,endDate,sidoList,sigunguList} = this.state;
      
      return (
        <>

        <div className='topComponent'>
              <div>
                    <span>유류비 계산기</span>
                    <br/>
                    <span>지역 </span>
                      <select type="text" name="sido" value={sido} onChange={this.handleChange}>
                        <option defaultValue=""></option>
                        {sidoList.map(Item => {
                          return <option key={Item.code} value={Item.id}>{Item.name}</option>
                        })}
                      </select>
                      <select type="text" name="sigungu" value={sigungu} onChange={this.handleChange}>
                        <option defaultValue=""></option>
                        {sigunguList.map(Item => {
                          return <option key={Item.code} value={Item.id}>{Item.name}</option>
                        })}
                      </select>
              </div>
              <div className='date'>
                <span>계산기간</span>
                  <DatePicker 
                    dateFormat="yyyy-MM-dd"
                    selected={startDate} 
                    locale={ko}
                    onChange={this.startDateChange}
                  />
                  <DatePicker 
                  dateFormat="yyyy-MM-dd"
                  selected={endDate} 
                  locale={ko}
                  onChange={this.endDateChange}
                />
                </div>
                <div className='work'>
                  <label>
                      출근일수:
                      <input
                        type="number"
                        name="work"
                        value={work}
                        onChange={this.handleInput}
                      />
                    </label>
                    <label>
                    <input
                        type="checkbox"
                        name="trip"
                        value={!trip}
                        onChange={this.changeCheckBox}
                      />
                        출장
                  </label>
                </div>
          
                <span>유종 </span>
                <select type="text" name="oilType" value={oilType} onChange={this.handleChange}>
                  <option defaultValue=""></option>
                  <option value="DIESEL">DIESEL</option>
                  <option value="GASOLINE">GASOLINE</option>
                  <option value="LPG">LPG</option>
                  <option value="ELEC">ELECTRONIC</option>
                </select>
                <label>
                연비:
                <input type="number" name="effi" value={effi} onChange={this.handleInput} />
                </label>
        </div>
          
          <label>
            거리(편도):
            <input
              type="number"
              name="dist"
              value={dist}
              onChange={this.handleInput}
            />
          </label>
          <label> 
            
            <input
              type="checkbox"
              name ="sinhutt"
              value={!shutt}
              onChange={this.changeCheckBox}
            />
              왕복 
          </label>
                        
          <br/>
          <button onClick={this.changeText}>결과보기</button>
          <Capture state={this.state}></Capture>       
          <div>
          
        </div>
            
        </>

    ); 
  };
}

export default App;
